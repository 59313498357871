@keyframes mount-1 {
    0% {
        transform: translateY(1.5rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

@keyframes mount-2 {
    0% {
        transform: translateY(1.5rem);
        opacity: 0;
    }
    25% {
        transform: translateY(1.5rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.field-1 {
    animation: 1.8s ease 0s 1 mount-1;
}

.field-2{
    animation: 1.8s ease 0s 1 mount-2;
}

.field-3{
    animation: 1.8s ease 0s 1 mount-3;
}
